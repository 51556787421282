var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "popup_wrap cp-popup_wrap cp-childnode" }, [
    _c(
      "button",
      {
        staticClass: "layer_close",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.$emit("close")
          },
        },
      },
      [_vm._v("close")]
    ),
    _c("div", { staticClass: "popup_cont" }, [
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("cp.CTRL030P010.001"))),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("table", { staticClass: "tbl_row cp-table" }, [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(_vm.chkMapper, function (data, key) {
              return _c(
                "tr",
                { key: key },
                [
                  data.visible
                    ? [
                        _c(
                          "th",
                          {
                            staticClass: "chk",
                            class: {
                              "cp-th-disabled":
                                key === "profileYn" && _vm.isDisableProfile,
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.chkMapper[key].value,
                                  expression: "chkMapper[key].value",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                name: "no",
                                id: key,
                                "true-value": "Y",
                                "false-value": "N",
                                disabled:
                                  key === "profileYn" && _vm.isDisableProfile,
                              },
                              domProps: {
                                checked: Array.isArray(_vm.chkMapper[key].value)
                                  ? _vm._i(_vm.chkMapper[key].value, null) > -1
                                  : _vm._q(_vm.chkMapper[key].value, "Y"),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.chkMapper[key].value,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "Y" : "N"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.chkMapper[key],
                                          "value",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.chkMapper[key],
                                          "value",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.chkMapper[key], "value", $$c)
                                  }
                                },
                              },
                            }),
                            _c("label", { attrs: { for: key } }, [
                              _c("span", { staticClass: "offscreen" }, [
                                _vm._v("No"),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            class: {
                              "cp-th-disabled":
                                key === "profileYn" && _vm.isDisableProfile,
                            },
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "mr10", attrs: { for: key } },
                              [_c("span", [_vm._v(_vm._s(data.name))])]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          ),
        ]),
      ]),
      _vm.isDisableProfile
        ? _c("div", { staticClass: "cp-pre-container mb15" }, [
            _c("pre", { staticClass: "txt_desc cp-pre" }, [
              _vm._v(_vm._s(_vm.$t("cp.CTRL030P010.014"))),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "mt10 text_center" }, [
        _c(
          "a",
          {
            staticClass: "button blue lg",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.applyValid()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cp.COMMON.014")))]
        ),
        _c(
          "a",
          {
            staticClass: "button blue lg",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cp.COMMON.007")))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "38px" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }