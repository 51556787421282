<template>
  <div class="popup_wrap cp-popup_wrap cp-childnode">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL030P010.001') }}</h1>
      <div class="content_box mt10">
        <table class="tbl_row cp-table">
          <colgroup>
            <col width="38px">
            <col>
          </colgroup>
          <tbody>
            <tr v-for="(data, key) in chkMapper" :key="key">
              <template v-if="data.visible">
                <th class="chk" :class="{'cp-th-disabled': key === 'profileYn' && isDisableProfile }">
                  <input
                    type="checkbox"
                    name="no"
                    :id="key"
                    v-model="chkMapper[key].value"
                    true-value="Y"
                    false-value="N"
                    :disabled="key === 'profileYn' && isDisableProfile"
                  >
                  <label :for="key">
                    <span class="offscreen">No</span>
                  </label>
                </th>
                <th :class="{'cp-th-disabled': key === 'profileYn' && isDisableProfile }">
                  <label :for="key" class="mr10">
                    <span>{{ data.name }}</span>
                  </label>
                </th>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="isDisableProfile" class="cp-pre-container mb15">
        <pre class="txt_desc cp-pre">{{ $t('cp.CTRL030P010.014') }}</pre>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="applyValid()">{{ $t('cp.COMMON.014') }}</a>
        <a class="button blue lg" href="#" @click.prevent="$emit('close')">{{ $t('cp.COMMON.007') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TargetCondApplyPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  data () {
    return {
      isDisableProfile: false,
      chkMapper: {
        routeYn: { name: this.$t('cp.CTRL030P010.002'), value: 'N', visible: false },
        itemYn: { name: this.$t('cp.CTRL030P010.003'), value: 'N', visible: false },
        frtPncYn: { name: this.$t('cp.CTRL030P010.004'), value: 'N', visible: false },
        bidgCstYn: { name: this.$t('cp.CTRL030P010.005'), value: 'N', visible: false },
        cntrSzTypYn: { name: this.$t('cp.CTRL030P010.006'), value: 'N', visible: false },
        bkgShipperYn: { name: this.$t('cp.CTRL030P010.007'), value: 'N', visible: false },
        actShipperYn: { name: this.$t('cp.CTRL030P010.008'), value: 'N', visible: false },
        hcneShipperYn: { name: this.$t('cp.CTRL030P010.009'), value: 'N', visible: false },
        profileYn: { name: this.$t('cp.CTRL030P010.010'), value: 'N', visible: false }
      }
    }
  },
  mounted () {
    this.setKeyCheck()
  },
  methods: {
    setKeyCheck () {
      const groupKeyCheck = this.parentInfo.sfOpt.groupKeyCheck
      this.isDisableProfile = groupKeyCheck.profileYn === 'Y' && groupKeyCheck.bkgShipperYn !== 'Y'

      for (const key of Object.keys(this.chkMapper)) {
        if (groupKeyCheck[key] === 'Y') {
          this.chkMapper[key].value = 'Y'
          this.chkMapper[key].visible = true
        }
      }
    },
    applyValid () {
      if (Object.values(this.chkMapper).filter(row => row.value === 'Y').length === (this.isDisableProfile ? 1 : 0)) {
        this.openAlert(this.$t('cp.CTRL030P010.011'))
      } else if (!this.isDisableProfile && this.chkMapper.profileYn.value === 'Y' && this.chkMapper.bkgShipperYn.value !== 'Y') {
        this.openAlert(this.$t('cp.CTRL030P010.014'))
      } else {
        this.asyncAlert(this.$t('cp.CTRL030P010.012'))
      }
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    async asyncAlert (msg) {
      const obj = {
        alertType: 'normal',
        showXclose: true,
        customCloseBtnClass: 'button blue lg cp-childnode',
        customCloseBtnText: this.$t('cp.COMMON.007'),
        customConfirmBtnClass: 'button blue lg',
        customConfirmBtnText: this.$t('cp.COMMON.005'),
        useConfirmBtn: true,
        title: this.$t('cp.CTRL030P010.013'),
        message: msg,
        type: 'info'
      }
      if (await this.$ekmtcCommon.asyncAlert(obj)) {
        this.apply()
      }
    },
    layerClose () {
      this.$emit('close')
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    },
    apply () {
      const applyKeyList = Object.entries(this.chkMapper).filter(entry => entry[1].value === 'Y').map(entry => entry[0].slice(0, -2))

      if (applyKeyList.indexOf('profile') && this.isDisableProfile) {
        applyKeyList.splice(applyKeyList.indexOf('profile'), 1)
      }

      if (applyKeyList.includes('item')) {
        applyKeyList.push('itemGrp')
      }

      this.$emit('close', { applyKeyList: applyKeyList })
      this.layerClose()
    }
  }
}
</script>
<style>
  .popup_dim:has(.cp-childnode) {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .cp-popup_wrap {
    width: 350px;
    margin-bottom: 100px;
  }

  .simplert__content:has(.cp-childnode) {
    width: 550px !important;
  }

  .cp-th-disabled {
    color: #aaaaaa !important;
    background-color: #f6f6f6 !important;
  }
</style>
